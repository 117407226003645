<template>
    <v-container fluid style="margin-bottom: 100px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title class="pb-1 pt-1">
                        Filter
                    </v-card-title>
                    <v-progress-linear
                        :active="loading"
                        :indeterminate="loading"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                    ></v-progress-linear>
                        <v-container fluid>
                            <v-row>
                                <v-col cols="12" xs="12" sm="5" md="2">
                                    <v-autocomplete  solo clearable v-model="office" :items="offices" item-value="office_id" item-text="office" label="MILL ID" class="ma-0 pa-0" hide-details=true></v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" sm="6" md="3">
                        <v-autocomplete 
                            prepend-inner-icon="mdi-account-box" 
                            solo 
                            v-model="cust_id" 
                            clearable 
                            :items="customers" 
                            item-value="cust_id" 
                            item-text="value_data" 
                            :search-input.sync="search" cache-items hide-no-data label="Customer"
                            class="ma-0 pa-0"
                            hide-details=true
                        ></v-autocomplete> 
                        </v-col>
                             <v-col cols="12" xs="12" sm="5" md="2">
                                 <v-menu ref="modal" v-model="modal" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                    <template v-slot:activator="{ on, attr }">
                                    <v-text-field solo v-model="date_from" label="Date from" prepend-icon="mdi-calendar" clearable readonly class="ma-0 pa-0" hide-details=true v-bind="attr" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date_from" no-title @input="modal = false"></v-date-picker>
                                 </v-menu>
                            </v-col>
                               <v-col cols="12" xs="12" sm="3" md="2">
                                <v-menu ref="modal_to" v-model="modal_to" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">   
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field solo v-model="date_to" label="Date to" prepend-icon="mdi-calendar" clearable readonly v-bind="attrs" v-on="on" class="ma-0 pa-0" hide-details=true></v-text-field>
                                    </template>
                                  <v-date-picker v-model="date_to" no-title @input="modal_to = false"></v-date-picker>
                                        </v-menu>
                                </v-col>
                                <v-col cols="12" xs="12" sm="5" md="2">
                                    <v-autocomplete  solo clearable v-model="pay_term_id" :items="pays" item-value="pay_term_id" item-text="pay_term_id" label="PAY TERM" class="ma-0 pa-0" hide-details=true></v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" sm="5" md="1">
                                    <v-autocomplete  solo clearable v-model="paid_flag" :items="statuses" item-value="paid_flag" item-text="status" label="PAID FLAG" class="ma-0 pa-0" hide-details=true></v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" sm="5" md="2" >
                                </v-col>
                                <v-col cols="12" xs="12" sm="12">
                                <v-radio-group
                                    v-model="invoice"
                                    row
                                    class="mt-0 pa-0"
                                    hide-details=true>
                                    <v-radio
                                        label="By Date Inv"
                                        value="byDateInv"></v-radio>
                                </v-radio-group>
                                 </v-col>
                                  <v-col cols="12" xs="12" sm="12">   
                                <v-radio-group
                                    v-model="invoice"
                                    row
                                    class="mt-3 pa-0"
                                    hide-details=true>
                                    <v-radio
                                        label="By Due Date"
                                        value="byDueDate"></v-radio>
                                </v-radio-group>
                                </v-col>
                                <v-col cols="12" xs="12" sm="12">
                                       <v-radio-group
                                    v-model="invoice"
                                    row
                                    class="mt-3 pa-0"
                                    hide-details=true>
                                    <v-radio
                                        label="By Late Paid"
                                        value="byLatePaid"></v-radio>
                                </v-radio-group> 
                                     </v-col>       
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn class="mt-1" 
                                color="info" elevation="2" large @click="submit()">Search</v-btn>
                            </v-col>           
                             <!-- <v-col cols="12" xs="12" sm="5" md="12" >
                                <v-btn color="error" elevation="2" large class="mb-5" @click="submit()">
                                    <v-icon dark>
                                        mdi-magnify
                                    </v-icon>
                                    Search
                                </v-btn>
                            </v-col> -->
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
            <v-col cols="12" style="padding-bottom: 10px">
                <v-skeleton-loader v-if="loading_loader" :loading="loading_loader" type="card, table"></v-skeleton-loader>
                <div v-show="loading_loader === false">
                    <v-container fluid>
                        <v-row>
                            <v-card-title>Result
                            </v-card-title>

                            <v-col cols="12" xs="12" sm="12" md="12">
                                <v-card
                                    class="mx-auto"
                                    color="blue lighten-5"
                                >
                                    <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-h5" style="color : green">
                                        Summary
                                        </v-list-item-title>
                                        <v-list-item-subtitle style="color :green">Piutang Report</v-list-item-subtitle>
                                    </v-list-item-content>
                                    </v-list-item>

                                    <v-card-text>
                                    <v-row align="center">
                                        <v-col cols="12">
                                            <v-list-item>
                                            <v-list-item-icon>
                                                <v-icon>mdi-currency-usd</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-subtitle style="color : green">TOTAL INVOICE : <span style="float: right;">{{total.total_inv ? total.total_inv : 0}}</span></v-list-item-subtitle>
                                            </v-list-item>
                                        </v-col>
                                          <v-col cols="12">
                                            <v-list-item>
                                            <v-list-item-icon>
                                                <v-icon>mdi-cash</v-icon> 
                                            </v-list-item-icon>
                                            <v-list-item-subtitle style="color : red">TOTAL PIUTANG : <span style="float: right;">{{total.total_piutang ? total.total_piutang : 0}}</span></v-list-item-subtitle>
                                            </v-list-item>
                                        </v-col>
                                    </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row>                             
                            <v-col cols="12" xs="12" sm="12" md="12">
                                <v-data-table
                                    loading-text="Please wait, retrieving data"
                                    :headers="headers"
                                    :items="summaryInvo"
                                    :loading="loading"
                                    :server-items-length="totalItemsSummary"
                                    :page.sync="paginationSummary"
                                    :options.sync="optionsSummary"
                                    page-count="10"
                                    class="elevation-1"
                                >
                                    <template v-slot:[`header.total_inv`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.cust_name`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.amt_total`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.amt_paid`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.total_paid`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                     <template v-slot:[`header.paid_flag`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.total_piutang`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
            </v-col>
             <v-col cols="12" style="padding-bottom: 100px">
                <v-skeleton-loader v-if="loading_loader" :loading="loading_loader" type="card, table"></v-skeleton-loader>
                <div v-show="loading_loader === false">
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="12">
                                <v-card
                                    class="mx-auto"
                                    color="red lighten-5"
                                >
                                    <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title class="text-h5">
                                        Overview 
                                        </v-list-item-title>
                                        <v-list-item-subtitle>Piutang Report</v-list-item-subtitle>
                                    </v-list-item-content>
                                    </v-list-item>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row>
                                                        
                            <v-col cols="12" xs="12" sm="12" md="12">
                                <v-data-table
                                    :headers="headersss"
                                    :items="raw_materials2"
                                    :loading="loading"
                                    loading-text="Please wait, retrieving data"
                                    :server-items-length="totalItemsOverview"
                                    :page.sync="paginationOverview"
                                    :options.sync="optionsOverview"
                                    page-count="10"
                                    class="elevation-1"
                                >
                                    <template v-slot:[`header.cust_name`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.inv_id`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.order_id`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.dt_inv`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.dt_due`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.late`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.sales_name`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.amt_total`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.amt_disc_pay`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.amt_paid`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.amt_retur`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`header.paid_flag`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                     <template v-slot:[`header.Piutang`]="{ header }">
                                        <button @click="sortByFunc(header.value)">{{header.text}}</button>
                                        <i v-if="sortBy === 'desc'" aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-up theme--light" style="font-size: 18px;"></i>
                                        <i v-else aria-hidden="true"  @click="sortByFunc(header.value)" class="v-icon notranslate v-data-table-header__icon mdi mdi-arrow-down theme--light" style="font-size: 18px;"></i>
                                    </template>
                                    <template v-slot:[`item.actions`]="{ item }">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon small class="mr-2" @click="showDetail2(item)" v-on="on">
                                                            mdi-eye
                                                        </v-icon>
                                                    </template>
                                                    <span>Show</span>
                                                </v-tooltip>
                                            </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
            </v-col>
        </v-row>
        <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
            <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                    <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
            </v-layout>
            <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                <v-icon>clear</v-icon>
            </v-btn>
        </v-snackbar>

        <v-dialog v-model="dialog2" max-width="80%">                
            <v-card>
                <v-card-title>Detail of invoice : {{ this.strInvID }}</v-card-title>
                <v-card-text>
                        <v-container fluid>
                        <v-row>
                            <v-col cols="12">
                                <v-card-title>Result
                                    <v-spacer></v-spacer>
                                </v-card-title>

                                <v-data-table
                                    :headers="header_po_items_payment"
                                    :items="po_itemsDetailPayment"
                                    :loading="loading4"
                                    loading-text="Please wait, retrieving data"
                                    page-count="10"
                                    class="elevation-1">
                                    <template v-slot:[`item.actions`]="{ item }">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-icon small class="mr-2" @click="showDetail2(item)" v-on="on">
                                                    mdi-eye
                                                </v-icon>
                                            </template>
                                            <span>Show</span>
                                        </v-tooltip>
                                    </template>
                                </v-data-table>

                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close2()">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
</template>
<script>
export default {
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'Sunrise Mill',
                disabled: false,
                href: '/admin/sm',
                },
                {
                text: 'SM Piutang',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Piutang Report',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            loading:false,
            offices: [{
                office_id : 'SM',
                office : 'Sunrise Mill'
            }],
            office: 'SM',
            pays: [],
            cust_name:'',
            paid_flag:'',
            status:'',
            statuses:[
             {
                    status: 'N',
                    value: 'O'
                },
                {
                    status: 'Y',
                    value: 'C'
                }    

            ],
            customers:[],
            pay_term_id:'',
            customer: '',
            items:[],
            dialog: false,
            raw_materials2: [],
            search: null,
            headersss: [
                {
                    text: 'Customer Name',
                    align: 'start',
                    sortable: false,
                    value: 'cust_name',
                },
                { text: 'Invoice ID', value: 'inv_id', sortable: false },
                { text: 'Order ID', value: 'order_id', sortable: false },
                { text: 'DT Inv', value: 'dt_inv', sortable: false },
                { text: 'Due Date', value: 'dt_due', sortable: false },
                { text: 'Late', value: 'late', sortable: false },
                { text: 'Salesman', value: 'salesman_name', sortable: false },
                { text: 'Pay Term', value: 'pay_term_id', sortable: false },
                { text: 'Amt.Total', value: 'amt_total', sortable: false },
                { text: 'Amt.Disc.Pay', value: 'amt_disc_pay', sortable: false },
                { text: 'Amt.Paid', value: 'amt_paid', sortable: false },
                { text: 'Amt.Retur', value: 'amt_retur', sortable: false },
                { text: 'Paid Flag', value: 'paid_flag', sortable: false },
                { text: 'Piutang', value: 'Piutang', sortable: false },
                { text: 'Detail', value: 'actions', sortable: false },
            ],
           
            loadingCust: false,
            display: 'none',
            loading3: false,
            loading4: false,
            loading5: false,
            loading_loader: false,
            sortBy: 'asc',
            sortName: '',
            summaryInvo: [],
            search: null,
            headers: [
                {
                    text: 'Total Invoice',
                    align: 'start',
                    sortable: false,
                    value: 'total_inv',
                },
                { text: 'Customer Name', value: 'cust_name', sortable: false },
                { text: 'Amt Total', value: 'amt_total', sortable: false },
                { text: 'Amt Paid', value: 'amt_paid', sortable: false },
                { text: 'Total Amt Paid', value: 'total_paid', sortable: false },
                { text: 'Paid Flag', value: 'paid_flag', sortable: false },
                { text: 'Total Piutang', value: 'total_piutang', sortable: false },
              
               
            ],
            totalItems: 10,
            pagination: 1,
            itemPerPageOverview:10,
            itemPerPageSummary:10,
            totalItemsOverview: 10,
            paginationOverview: 1,
            totalItemsSummary: 10,
            paginationSummary: 1,
            options: {},
            optionsOverview:{},
            optionsSummary:{},
            cust_id: '',
            date_from: '',
            modal: false,
            date_to: '',
            modal_to: false,
            invoice: 'byDateInv',
            total: {},
            header_po_items_payment:[
                { text: 'PAY. DATE', value: 'dt_pay', sortable: false },
                { text: 'PAY. ID', value: 'pay_id', sortable: false },
                { text: 'USER', value: 'user_id', sortable: false },
                { text: 'REMARK', value: 'remark', sortable: false },
                { text: 'PAY. AMT.', value: 'pay_amt', sortable: false },                
            ],
            po_itemsDetailPayment: [],
            loading4: false,
            dialog2: false,
            strInvID: '',
            
        }
    },
    mounted(){
        this.GetPayTermm()
    },
    methods:{
        getEnvConf(){
            let data = this.data_var;

            this.$store.dispatch("getVariabelUser", { data }).then(res => {

                for (let i = 0; i < res.data.data.length; i++) {

                    if (res.data.data[i]['var_id'] === 'APRVPR1') {
                        if (res.data.data[i]['var_value'] != null) {
                            this.user_approve1 = res.data.data[i]['var_value']
                        }
                    }
                    
                    if (res.data.data[i]['var_id'] === 'APRVPR2') {
                        if (res.data.data[i]['var_value'] != null) {
                            this.user_approve2 = res.data.data[i]['var_value']
                        }
                    }

                    if (res.data.data[i]['var_id'] === 'RAW') {
                        if (res.data.data[i]['var_value'] != null) {
                            this.raw = res.data.data[i]['var_value']
                            this.disabled_search = true
                        }
                    }

                }

            })
        },

        async showDetail2(id){

            this.strInvID = id.inv_id
            this.po_itemsDetailPayment = []
            this.dialog2 = true

            this.loading4 = true

            await axios.get(`${process.env.VUE_APP_URL}/api/sm/piutang/getSummaryPayment?inv_id=${id.inv_id ? id.inv_id : ""}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading4 = false
                //console.log(res.data.data)
                // this.loading3 = false
                this.po_itemsDetailPayment = res.data.result
            })

        },

         close() {
            this.dialog = false
        },

        close2() {
            this.dialog2 = false
        },

        search_temp(){
            this.pagination_item = 1
            this.getPullDataDetail(1, 10, this.po_item)
        },
        showItem(item){
            this.po_item = item
            this.dialog = true
            this.pagination_item = 1
            this.getPullDataDetail(1, 10, this.po_item)
        },
          async querySelections (value) {
                await axios.get(`${process.env.VUE_APP_URL}/api/sm/piutang/getCustomer?search=${value}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.customers = res.data
                    // console.log(this.customers);
                });
            },
         sortByFunc(sortBy){
            this.sortBy = this.sortBy === 'desc' ? 'asc' : 'desc';
            this.sortName = sortBy
            this.submit(1, 10)
        },
        
        GetPayTermm (){
            axios.get(`${process.env.VUE_APP_URL}/api/sm/piutang/GetPay?=${this.pay_term_id ? this.pay_term_id : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                // console.log(res.data)
                this.pays = res.data
                this.loading2 = false
            });
        },
        
        
        async submit(page = 1, itemsPerPage = 10, item){
            this.loading = true
            this.loading_loader = true
            this.display = 'block'
            this.summary()
            this.summarytotal()
            this.overview();
        },

        async overview(page = 1, itemsPerPage = 10){
            var url = `${process.env.VUE_APP_URL}/api/sm/piutang/getOverviewPiutang?customer=${this.cust_id ? this.cust_id : ''}&paid=${this.paid_flag ? this.paid_flag : ""}&pay=${this.pay_term_id ? this.pay_term_id : ""}&dt_start=${this.date_from ? this.date_from : ""}&dt_end=${this.date_to ? this.date_to : ""}&byWhat=${this.invoice ? this.invoice : ''}&sort=${this.sortBy ? this.sortBy : 'desc'}&sortName=${this.sortName ? this.sortName : ''}&page=${page > 1 ? page : ''}&pagination=${this.itemPerPageOverview}`
            await axios.get(url,  { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.raw_materials2 = res.data.data
                this.totalItemsOverview = res.data.total
                this.paginationOverview = res.data.current_page
            })
        },
        async summary(page = 1, itemsPerPage = 10){
            var url = `${process.env.VUE_APP_URL}/api/sm/piutang/getSummaryPiutang?customer=${this.cust_id ? this.cust_id : ''}&paid=${this.paid_flag ? this.paid_flag : ""}&pay=${this.pay_term_id ? this.pay_term_id : ""}&dt_start=${this.date_from ? this.date_from : ""}&dt_end=${this.date_to ? this.date_to : ""}&byWhat=${this.invoice ? this.invoice : ''}&sort=${this.sortBy ? this.sortBy : 'desc'}&sortName=${this.sortName ? this.sortName : ''}&page=${page > 1 ? page : ''}&pagination=${this.itemPerPageSummary}`
            await axios.get(url,  { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading = false
                this.loading_loader = false
                this.summaryInvo = res.data.data
                this.totalItemsSummary = res.data.total
                this.paginationSummary = res.data.current_page
            })
        },

          async summarytotal(){
            var url = `${process.env.VUE_APP_URL}/api/sm/piutang/HitungTotal?customer=${this.cust_id ? this.cust_id : ''}&dt_start=${this.date_from ? this.date_from : ""}&dt_end=${this.date_to ? this.date_to : ""}&pay=${this.pay_term_id ? this.pay_term_id : ""}&paid=${this.paid_flag ? this.paid_flag : ""}&byWhat=${this.invoice ? this.invoice : ''}`
            await axios.get(url, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.total = res.data.total[0]
            })
        }
    
    },
    watch: {
        options: {
            handler () {
                    const { page, itemsPerPage } = this.options
                    if (this.display != 'none') {
                        this.submit(page, itemsPerPage)
                    }
                },
            deep: true
        },
        optionsOverview:{
            handler () {
                    const { page, itemsPerPage } = this.optionsOverview
                    if (this.display != 'none') {
                        this.itemPerPageOverview = itemsPerPage;
                        this.overview(page, itemsPerPage)
                    }
                },
            deep: true
        },
        optionsSummary:{
            handler () {
                    const { page, itemsPerPage } = this.optionsSummary
                    if (this.display != 'none') {
                        this.itemPerPageSummary = itemsPerPage;
                        this.summary(page, itemsPerPage)
                    }
                },
            deep: true
        },
        search (val) {
            val && val !== this.customer && this.querySelections(val)
        },
    }
}
</script>
<style scoped>
/* .suma {
    background-color: aqua;
} */
</style>